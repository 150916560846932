import {
  City,
  InstagramPostLocationLineableFragment,
} from '@/src/graphql/__generated__/graphql.ts';
import { ReactElement, useMemo } from 'react';
import {
  getCountryCodeEmoji,
  getCountryCodeTranslation,
} from '@/src/helpers/country.ts';
import { useTranslations } from '@/src/hooks/useTranslations';
import { cn } from '@/src/lib/utils.ts';
import { Emoji } from '@/src/components/ui/atoms/emoji.tsx';

type CityCountryLineProps = {
  cityName: Pick<City, 'name'> | string | null | undefined;
  countryCode?: string | null;
  showOnlyCountryEmoji?: boolean;
  className?: string;
};

export function CityCountryLine({
  cityName,
  countryCode,
  showOnlyCountryEmoji,
  className,
}: CityCountryLineProps) {
  const trans = useTranslations();

  const calcCityName = typeof cityName === 'string' ? cityName : cityName?.name;

  const countryEmoji = useMemo(
    () => (countryCode ? getCountryCodeEmoji(countryCode) : undefined),
    [countryCode],
  );

  const countryName = useMemo(
    () =>
      countryCode ? getCountryCodeTranslation(countryCode, trans) : undefined,
    [countryCode],
  );

  if (!calcCityName && !countryName) return <></>;

  return (
    <span className={cn('inline-flex flex-wrap gap-x-3', className)}>
      {countryCode && (
        <span className="text-muted-foreground">
          <Emoji>{countryEmoji}</Emoji>
          {showOnlyCountryEmoji ? undefined : countryName}
        </span>
      )}
      {calcCityName && <span>{calcCityName}</span>}
    </span>
  );
}

export function InstagramPostLocationLineableCityCountryLineProvider({
  location,
  children,
}: {
  location: InstagramPostLocationLineableFragment;
  children: (
    props: CityCountryLineProps | undefined,
  ) => ReactElement | undefined;
}) {
  return children({
    cityName: location.name || location.city_prediction?.name,
    countryCode: location.city_prediction?.country_code,
    showOnlyCountryEmoji: true,
  });
}
