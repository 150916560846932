import React, {
  forwardRef,
  ReactElement,
  useState,
  useId,
  useMemo,
  ComponentRef,
  FC,
  useEffect,
} from 'react';
import type { Props as SelectProps } from 'react-select';
import { useClickAway } from '@uidotdev/usehooks';
import { Button } from '@/src/components/ui/button.tsx';
import { cn } from '@/src/lib/utils.ts';
import dynamic from 'next/dynamic';
import Spinner from '@/src/components/ui/atoms/spinner.tsx';

const Select = dynamic(() => import('react-select'), {
  loading: () => <Spinner className="relative -top-[0.2rem] size-4" />,
}) as FC<Props>;

type SelectTextProps = {
  fixedContextMenuWidth?: number;
  textPlaceholder?: (selectedLabel: string | undefined) => ReactElement;

  defaultValue?: ValueIdentifier;
  value?: ValueIdentifier | undefined;
  onChange?: (data: Value | undefined | null) => void;
};

type Value = { value: string; label: string };
type ValueIdentifier = Pick<Value, 'value'>;
type Props = Omit<
  SelectProps<Value>,
  'ref' | 'defaultValue' | 'value' | 'onChange'
> & {
  ref: any;
} & SelectTextProps;

function isValueIdentifier(value: unknown): value is ValueIdentifier {
  return typeof value === 'object' && !!value && 'value' in value;
}

export const TextSelect = forwardRef<ComponentRef<typeof Select>, Props>(
  (
    {
      fixedContextMenuWidth = 200,
      value,
      onChange,
      options = [],
      defaultValue,
      className,
      menuPlacement,
      textPlaceholder,
      ref: noop,
      ...rest
    }: Props,
    ref,
  ) => {
    const id = useId();
    const [menuOpen, setMenuOpen] = useState(false);
    const [innerValue, setInnerValue] = useState(value ?? defaultValue);

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    const clickOutsideRef = useClickAway<HTMLDivElement>((e) => {
      if (!(e.target as any).closest('button')) {
        setMenuOpen(false);
      }
    });

    const innerRefValue = options.find(
      (option) =>
        'value' in option &&
        innerValue &&
        'value' in innerValue &&
        option?.value == innerValue?.value,
    );

    const displayValue = useMemo(() => {
      if (textPlaceholder) {
        return textPlaceholder(innerRefValue?.label);
      }
      if (innerRefValue) return innerRefValue?.label;
      return 'Bitte wählen';
    }, [innerValue]);

    return (
      <span className="relative">
        <Button
          onClick={() => setMenuOpen((p) => !p)}
          type="button"
          variant="link"
          className="!h-auto !p-0"
        >
          {displayValue}
        </Button>

        <div
          className={cn({
            'absolute top-0 z-50': menuPlacement == 'top',
            'absolute bottom-0 z-50': !menuPlacement,
          })}
          ref={clickOutsideRef}
        >
          <div className={cn('bg-white text-black', { 'd-none': !menuOpen })}>
            {menuOpen && (
              <Select
                menuPosition="absolute"
                instanceId={id}
                menuPlacement={menuPlacement}
                components={{ Control: () => <></> } as any}
                menuIsOpen={menuOpen}
                value={innerValue}
                defaultValue={defaultValue}
                styles={{
                  menu: (e) => ({
                    ...e,
                    width: `${fixedContextMenuWidth}px`,
                  }),
                }}
                onChange={(data) => {
                  if (isValueIdentifier(data)) {
                    setInnerValue(data);
                    if (onChange) onChange(data as Value);
                  }
                }}
                ref={ref}
                options={options}
                closeMenuOnSelect={true}
                onMenuClose={() => setMenuOpen(false)}
                {...rest}
              />
            )}
          </div>
        </div>
      </span>
    );
  },
);
TextSelect.displayName = 'TextSelect';
