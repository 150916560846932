import OptimizedScraperMedia, {
  OptimoizedScraperMediaProps,
} from '@/src/components/optimized-scraper-media.tsx';
import { SimpleInstagramPostPreviewFragment } from '@/src/graphql/__generated__/apollo.tsx';
import { InstagramLogo } from '@/src/components/ui/icons/InstagramLogo.tsx';
import { useTranslations } from '@/src/hooks/useTranslations';
import { Like } from '@/src/components/ui/icons/like.tsx';
import { Comment } from '@/src/components/ui/icons/comment.tsx';
import { Separator } from '@/src/components/ui/separator';
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '@/src/components/ui/avatar';

type Props = {
  post: SimpleInstagramPostPreviewFragment;
  sizes?: OptimoizedScraperMediaProps['sizes'];
};

export function PreviewInstagramPost({ post, sizes }: Props) {
  const { rich: trans } = useTranslations();

  return (
    <div className="border-foreground/10 group relative flex h-full flex-col rounded-sm border ">
      <div className=" relative size-full h-[350px] overflow-hidden">
        <div className="relative z-10 flex h-full flex-col items-start justify-end gap-2 p-2">
          {post.sponsoring?.brand_name && (
            <div className="bg-foreground/60 z-10 ml-auto flex flex-row items-center justify-end gap-2 rounded-md px-2 py-2">
              <>
                <Avatar>
                  {post.sponsoring.brand?.logo && (
                    <OptimizedScraperMedia
                      className="aspect-square h-full w-full"
                      alt={`logo of ${post.sponsoring.brand_name}`}
                      media={post.sponsoring.brand?.logo}
                    />
                  )}
                  <AvatarFallback>{post.sponsoring.brand_name}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col items-end shadow-lg">
                  <span className="text-background/80 dark:text-background font-bold">
                    {post.sponsoring?.brand_name}
                  </span>
                  <Separator className="bg-secondary-foreground/40 mb-1" />
                  <span className="text-background/70 text-xs">
                    Werbung mit
                  </span>
                </div>
              </>
            </div>
          )}
          <div className="absolute left-0 top-0 size-full transition-all duration-300">
            <OptimizedScraperMedia
              className="size-full object-cover transition-all duration-300 group-hover:scale-105"
              media={post.media}
              sizes={sizes ?? ['100vw', { lg: 400 }]}
              alt={`instagram post`}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col p-3">
        <div className="mb-3 flex flex-row items-center gap-x-3">
          <Avatar>
            {post.network.profile_picture && (
              <OptimizedScraperMedia
                className="aspect-square h-full w-full"
                width={40}
                height={40}
                alt={`logo of ${post.network.full_name}`}
                media={post.network.profile_picture}
              />
            )}
          </Avatar>
          <div className="flex flex-col">
            <span className="text-foreground font-medium">
              {post.network.full_name}
            </span>
            <div className="text-muted-foreground text-xs">
              {trans('CREATOR.PREVIEW_INSTAGRAM_POST.POSTED_AT', {
                postedAt: new Date(post.posted_at),
              })}
            </div>
          </div>
          <InstagramLogo className="fill-muted-foreground/50 ml-auto size-8" />
        </div>
        <div className="font-web mb-3 line-clamp-3">{post.caption}</div>
        <div className="mt-auto flex flex-row items-center gap-3">
          <div className="flex flex-row gap-4">
            <span className="flex flex-row items-center gap-1">
              <span className="text-foreground/75 font-medium">
                {post.like_count}
              </span>
              <Like className="size-4" />
            </span>
            <span className="flex flex-row items-center gap-1">
              <span className="text-foreground/75 font-medium">
                {post.comment_count}
              </span>
              <Comment className="size-4" />
            </span>
            {post.engagement_percent && (
              <span className="flex flex-row items-center gap-1">
                <span className="text-foreground/75 font-medium">
                  {post.engagement_percent}%
                </span>
                <span className="text-foreground/50"> EGR</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
